<template>
  <section id="dashboard-analytics">
    <b-row class="match-height">
      <b-col lg="12 text-center">
        <b-card
    text-variant="center"
    class="card card-congratulations p-4"
  >
    <!-- images -->
    <b-img
      :src="require('@/assets/images/elements/decore-right.png')"
      class="congratulations-img-right"
    />
    <!--/ images -->

    <b-avatar
      variant="primary"
      size="70"
      class="shadow mb-2"
    >
      <feather-icon
        size="28"
        icon="UserIcon"
      />
    </b-avatar>
    <h1 class="mb-1 mt-50 text-white">
      Hola {{ userData.name }},
    </h1>
    <h5 class="mb-1 mt-50 text-white">
      {{ userData.email }},
    </h5>
    <b-card-text class="m-auto w-75">
      Bienvenido al Sistema de Nómina, ingresaste como <strong>{{userData.role}}</strong>
    </b-card-text>
  </b-card>
      </b-col>
    </b-row>
    

  </section>
</template>

<script>
import { BRow, BCol, BCard, BCardText, BAvatar, BImg } from 'bootstrap-vue'

import StatisticCardWithAreaChart from '@core/components/statistics-cards/StatisticCardWithAreaChart.vue'
import { kFormatter } from '@core/utils/filter'
import InvoiceList from '@/views/apps/invoice/invoice-list/InvoiceList.vue'
import AnalyticsCongratulation from './AnalyticsCongratulation.vue'
import AnalyticsAvgSessions from './AnalyticsAvgSessions.vue'
import AnalyticsSupportTracker from './AnalyticsSupportTracker.vue'
import AnalyticsTimeline from './AnalyticsTimeline.vue'
import AnalyticsSalesRadarChart from './AnalyticsSalesRadarChart.vue'
import AnalyticsAppDesign from './AnalyticsAppDesign.vue'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BAvatar,
    BImg,
    BCardText,
    AnalyticsCongratulation,
    AnalyticsAvgSessions,
    StatisticCardWithAreaChart,
    AnalyticsSupportTracker,
    AnalyticsTimeline,
    AnalyticsSalesRadarChart,
    AnalyticsAppDesign,
    InvoiceList,
    
  },
  data() {
    return {
      data : {},
      userData: JSON.parse(localStorage.getItem('userData')),
      
    }
  },
  created() {
    // data
    this.$http.get('/analytics/data')
      .then(response => { this.data = response.data })
  },
  methods: {
    kFormatter,
  },
}
</script>
